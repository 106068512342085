html,
body,
#root {
  margin: 0;
  display: flex;
  flex-grow: 1;
  overflow-x: auto;
  position: relative;
  height: fit-content;
  min-height: 100vh !important;
}
